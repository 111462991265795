import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex flex-row items-center space-x-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: 
        `text-${_ctx.textSize}px xxl:text-14px px-2 py-0 text-${
          _ctx.textColor.includes('text-')
            ? _ctx.textColor.replace('text-', '')
            : _ctx.textColor
        } bg-${
          _ctx.color.includes('bg-')
            ? _ctx.color.replace('bg-', '')
            : _ctx.disabled
            ? 'gray-lp-600'
            : _ctx.color
        } ${_ctx.rounded ? 'rounded-full' : 'rounded'} ${
          _ctx.shadow ? 'border border-black' : ''
        } font-${_ctx.weight}`
      ,
      style: `${_ctx.shadow ? 'box-shadow: 2px 2px 0px #000000;' : ''}`
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", {
            class: "capitalize",
            style: 
              `${
                _ctx.shadow
                  ? _ctx.disabled
                    ? 'text-shadow: 1px 1px 0px #000000, 2px 2px 0px #78787A;'
                    : 'text-shadow: 1px 1px 0px #000000, 2px 2px 0px #AD0020;'
                  : ''
              }`
            
          }, _toDisplayString(_ctx.label), 5),
          (_ctx.icon)
            ? (_openBlock(), _createBlock("img", {
                key: 0,
                src: require(`@/app/ui/assets/svg/${_ctx.icon}.svg`),
                class: "w-5",
                alt: "icon"
              }, null, 8, ["src"]))
            : _createCommentVNode("", true)
        ])
      ])
    ], 6)
  ]))
}