
import { prop, Vue } from "vue-class-component";

class Props {
  label = prop<string>({
    default: "",
    type: String
  });
  color = prop<string>({
    default: "gray-lp-200",
    type: String
  });
  textColor = prop<string>({
    default: "black-lp-300",
    type: String
  });
  textSize = prop<number>({
    default: 12,
    type: Number
  });
  weight = prop<string>({
    default: "normal",
    type: String
  });
  icon = prop<string>({
    default: "",
    type: String
  });
  rounded = prop<boolean>({
    default: false,
    type: Boolean
  });
  shadow = prop<boolean>({
    default: false,
    type: Boolean
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
}
export default class Chips extends Vue.with(Props) {}
